import React, { useState, useMemo } from 'react';
import { Surface } from 'recharts';

const CustomLegend = ({ payload, disable, setDisable }) => {
    return (
        <ul className="recharts-default-legend" style={{ padding: '0px', margin: '0px', textAlign: 'center' }}>
            {payload.map((legend, i) => {
                return (
                    <li
                        key={legend.id}
                        className={`recharts-legend-item legend-item-${i}`}
                        style={{ cursor: 'pointer', display: 'inline-block', marginRight: '10px' }}
                        onClick={e => {
                            setDisable({ ...disable, [legend.id]: !disable[legend.id] });
                        }}
                    >
                        <Surface
                            width={14}
                            height={14}
                            viewBox={{ x: 0, y: 0, width: 32, height: 32 }}
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                marginRight: '4px',
                                opacity: disable[legend.id] ? 0.5 : 1,
                            }}
                        >
                            {/*<Symbols className={'recharts-legend-icon'} type="square" fill={legend.color} />*/}
                            <path
                                stroke="none"
                                fill={legend.color}
                                d="M0,4h32v24h-32z"
                                className="recharts-legend-icon"
                            />
                        </Surface>
                        <span className="recharts-legend-item-text" style={{ opacity: disable[legend.id] ? 0.5 : 1 }}>
                            {legend.value}
                        </span>
                    </li>
                );
            })}
        </ul>
    );
};

const useToggleLegend = ({ data }) => {
    const [disable, setDisable] = useState({});
    const ToggleLegendRender = ({ payload }) => {
        return CustomLegend({ payload, disable, setDisable });
    };
    // const filteredData = useMemo(() => data.filter(d => !disable[d.name]), [data, disable]);
    const filteredData = useMemo(() => data.map(d => (disable[d.key] ? { ...d, value: 0 } : d)), [data, disable]);
    return { ToggleLegendRender, filteredData, disable };
};

export default useToggleLegend;
