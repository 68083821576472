import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const CountSpan = styled.span`
    font-weight: bold;
    font-size: 2rem;
`;

const CongestionSummary = ({ count, totalCount, label, time = false, sec }) => {
    return (
        <div>
            {!time ? (
                <div className={'d-flex flx-col justify-content-center align-items-center'}>
                    <CountSpan>
                        {count}/{totalCount}
                    </CountSpan>
                    <span className={'font-weight-bold'}>{label}</span>
                </div>
            ) : (
                <div className={'d-flex flx-col justify-content-center align-items-center'}>
                    <div>
                        {sec >= 60 && <CountSpan>{moment(sec * 1000).format('m')}m</CountSpan>}
                        <CountSpan>{moment(sec * 1000).format('s')}s</CountSpan>
                    </div>
                    <span className={'font-weight-bold'}>{label}</span>
                </div>
            )}
        </div>
    );
};

export default CongestionSummary;
