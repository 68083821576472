import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    filterOpen: false,
};

const { actions, reducer } = createSlice({
    name: 'filterSearchGroup',
    initialState,
    reducers: {
        setFilterOpen: (state, action) => {
            state.filterOpen = action.payload;
        },
    },
});

export const { setFilterOpen } = actions;
export default reducer;
