import React, { useContext, useEffect, useState } from 'react';
import { LineChart, Line, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine } from 'recharts';
import { SensorStatusStateContext } from '../../../index';

const SensorLineGraph = ({ dataKey = 'regDate', selectedSensingType }) => {
    const { sensorLineChartData, selectedSensor } = useContext(SensorStatusStateContext);

    const [limit, setLimit] = useState({
        maxRiskLimit: 0,
        minRiskLimit: 0,
    });

    //minRiskLimit, maxRiskLimit
    useEffect(() => {
        if (selectedSensor) {
            const { sensorItems } = selectedSensor;
            const { minRiskLimit, maxRiskLimit } = sensorItems.find(
                sensorItem => selectedSensingType === sensorItem.sensingType,
            );

            setLimit({
                maxRiskLimit,
                minRiskLimit,
            });
        }
    }, [selectedSensor]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={sensorLineChartData}>
                <XAxis dataKey={dataKey} />
                <YAxis />
                <Line type="monotone" dataKey={'data'} stroke="#8884d8" fill="#8884d8" />
                <ReferenceLine y={limit.maxRiskLimit} stroke="red" label="Max Risk Limit" />
                <ReferenceLine y={limit.minRiskLimit} stroke="red" label="Min Risk Limit" />
                <Legend />
                <Tooltip />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SensorLineGraph;
