import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-dropdown-select';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
    background-color: #fff;
    & .react-dropdown-select-input {
        display: none;
    }
`;

const FloorSelect = ({ selectedFloorId, handleChange }) => {
    const { selectableFloorList } = useSelector(state => state.FloorInfo);
    const [selectedFloor, setSelectedFloor] = useState([]);

    useEffect(() => {
        if (selectableFloorList.length) {
            if (selectedFloorId) {
                setSelectedFloor([selectableFloorList.find(v => v.floorId === selectedFloorId)]);
            } else {
                setSelectedFloor([selectableFloorList[0]]);
            }
        }
    }, [selectedFloorId, selectableFloorList]);

    return (
        // <Input type="select" name="floor" defaultValue={selectedFloor}>
        //     {selectableFloorList.map(floor => <option key={floor.floorId} value={floor.floorId}>
        //         {floor.floorName}
        //     </option>)}
        // </Input>
        <StyledSelect
            options={selectableFloorList}
            values={selectedFloor}
            valueField={'floorId'}
            labelField={'floorName'}
            searchable={false}
            dropdownGap={0}
            style={{ width: '150px' }}
            onChange={handleChange}
        />
    );
};

export default React.memo(FloorSelect);
