import React from 'react';
import { LabelProps } from '../type';

const Label = ({
    name,
    value,
    labelIcon,
    labelGroupClassName,
    labelValueClassName = '',
    infoBoxValue,
}: LabelProps): JSX.Element => {
    return (
        <div className={`pnt-label--group ${labelGroupClassName}`}>
            {labelValueClassName.includes('label-icon') && <span className={labelIcon} />}
            <div className={`label-main ${labelValueClassName}`}>
                {name}
                {labelValueClassName.includes('label-info') && (
                    <>
                        <span className={'icon-info-fill'} />
                        <div className="info-box">{infoBoxValue}</div>
                    </>
                )}
            </div>
            {value && <div className={`label-options`}>{value}</div>}
        </div>
    );
};

export default Label;
