import axios from 'axios';
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

const baseURL = 'http://13.125.33.143:8401';

const yjAxios = axios.create({ baseURL });

const reqGet = (url, param) => yjAxios.get(url, { params: param });

export const getPatientEws = param => reqGet('/healthcare/patient/ews', param);
export const getPatientState = param => reqGet('/healthcare/patient/monitoring', param);
