export const GOOGLE_TILE_URL = 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=pl';
export const SUB_DOMAIN = ['mt0', 'mt1', 'mt2', 'mt3'];

export const BLACK_TILE_URL = 'https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png';

export const defaultOptions = {
    zoomControl: false,
    boxZoom: false,
    zoomSnap: 0.1,
    zoom: 20,
    minZoom: 10,
    maxZoom: 24,
    scrollWheelZoom: true,
    doubleClickZoom: false,
    center: [37.50732992140603, 127.05376669764522],
    dragging: true,
    attributionControl: false,
    animate: false,
    zoomAnimation: false,
    fadeAnimation: false,
};
