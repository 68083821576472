import React, { useState, useEffect, useCallback } from 'react';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import StreamingPlugin from 'chartjs-plugin-streaming';
import { CHART_COLORS } from '../config';
import moment from 'moment';

// Chart.js에 실시간 차트 플러그인 넣기
Chart.register(StreamingPlugin);

const MILLISECOND_TO_SECOND = 1000;

const ResponsiveLineChart = ({ data, tagOption = 20, dataInteger = false }) => {
    const [chartData, setChartData] = useState({});

    // 시간을 이용해 차트에 찍는방식
    const now = moment().valueOf();

    // 데이터를 넣을 배열들을 value로 가진, 객체를 만듬
    useEffect(() => {
        const dataGathering = {};
        for (let key in data) {
            dataGathering[key] = [];
        }
        setChartData(dataGathering);
    }, []);

    const getColor = useCallback(idx => {
        return CHART_COLORS[idx % CHART_COLORS.length];
    }, []);

    const getDatasets = () => {
        let colorIdx = 0;
        const datasets = [];
        for (let key in chartData) {
            const color = getColor(colorIdx);
            datasets.push({
                label: `${key}`,
                backgroundColor: color,
                borderColor: color,
                borderWidth: 3,
                cubicInterpolationMode: 'monotone',
                fill: false,
                data: chartData[key],
            });
            colorIdx += 1;
        }
        return datasets;
    };

    return (
        <Line
            type="line"
            width={'100%'}
            height={'100%'}
            data={{
                datasets: getDatasets(),
            }}
            options={{
                // 이 옵션을 넣어야지 위젯크기에 따라 size가 조정됨
                maintainAspectRatio: false,
                animation: {
                    duration: 0,
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'left',
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            font: {
                                size: 11,
                                weight: 'bold',
                            },
                        },
                        onHover: event => {
                            event.native.target.style.cursor = 'pointer';
                        },
                        onLeave: event => {
                            event.native.target.style.cursor = 'default';
                        },
                    },
                    tooltip: {
                        position: 'nearest',
                        mode: 'index',
                        intersect: false,
                        usePointStyle: true,
                        pointStyle: 'circle',
                        cornerRadius: 0,
                        caretSize: 0,
                        backgroundColor: '#fff',
                        titleColor: '#000000',
                        bodyColor: '#000000',
                        borderColor: '#000000',
                        borderWidth: 0.15,
                        callbacks: {
                            // 아무것도 리턴하지 않으면 borderColor를 따라감
                            labelTextColor: () => {},
                        },
                    },
                },
                scales: {
                    y: {
                        min: 0,
                        ticks: {
                            // y축 단위를 정수 단위로 바꿈
                            callback: dataInteger
                                ? value => {
                                      if (value % 1 === 0) {
                                          return value;
                                      }
                                  }
                                : undefined,
                        },
                    },
                    x: {
                        type: 'realtime',
                        time: {
                            parser: 'timeFormat',
                            // 24시간 format으로 표시
                            tooltipFormat: 'HH:mm:ss.SSS',
                            displayFormats: {
                                second: 'HH:mm:ss',
                                minute: 'HH:mm:ss',
                                hour: 'HH:mm:ss',
                            },
                        },
                        realtime: {
                            delay: 1.5 * MILLISECOND_TO_SECOND,
                            duration: tagOption * MILLISECOND_TO_SECOND,
                            frameRate: 45,
                            // 최적화를 위해 1분 동안만 데이터 보관
                            ttl: 60 * MILLISECOND_TO_SECOND,
                            onRefresh: chart => {
                                for (let key in chartData) {
                                    const idx = chart.data.datasets.findIndex(el => el.label === key);
                                    chart.data.datasets[idx].data.push({
                                        x: now,
                                        y: data[key],
                                    });
                                }
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default ResponsiveLineChart;
