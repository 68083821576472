import React, { createContext, useReducer } from 'react';
import reducer, { initialState } from './filterSearchGroupReducer';
import cx from 'classnames';
export const FilterSearchGroupStateContext = createContext();
export const FilterSearchGroupDispatchContext = createContext();
const FilterSearchGroup = ({ children, className }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <FilterSearchGroupDispatchContext.Provider value={dispatch}>
            <FilterSearchGroupStateContext.Provider value={state}>
                <div className={cx('filter_ver4', className && className)}>{children}</div>
            </FilterSearchGroupStateContext.Provider>
        </FilterSearchGroupDispatchContext.Provider>
    );
};

export default FilterSearchGroup;
