import React from 'react';
import Draggable from '@components/Draggable';
import { Toast, ToastHeader, ToastBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const AlarmDetailPopup = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    selectedEvent,
    position,
    ...restProps
}) => {
    const { t } = useTranslation();
    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds=".app-main__inner" //cancel=".toast-body"
            // offsetParent={document.querySelector('.app-main__inner')}
            // positionOffset={{ x: '-50%', y: '-50px' }}
            // position={position}
            defaultPosition={position}
            // positionOffset={!isMounted ? { x: '-50%', y: '-50%' } : null}
            // defaultClassName={'draggable-center'}
        >
            <Toast
                isOpen={modal}
                style={{
                    position: 'absolute',
                    zIndex: 100,
                    backgroundColor: '#fff',
                }}
            >
                <ToastHeader toggle={toggleModal}>
                    {t('TagStateAlarmList;Detail information of the selected event')}
                </ToastHeader>
                <ToastBody>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <th scope="row" style={{ width: '40%', textAlign: 'right' }}>
                                    {t('TagStateAlarmList;ID')}
                                </th>
                                <td>{selectedEvent.targetId}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: '40%', textAlign: 'right' }}>
                                    {t('TagStateAlarmList;Name')}
                                </th>
                                <td>{selectedEvent.targetName}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: '40%', textAlign: 'right' }}>
                                    {t('TagStateAlarmList;Category')}
                                </th>
                                <td>{selectedEvent.categoryName}</td>
                            </tr>
                        </tbody>
                    </Table>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default AlarmDetailPopup;
