import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    floorInfo: {},
    selectedFloor: '',
    realTimeLog: { count: 0, data: [] },
    iotItemList: [],
    geofenceInfo: [],
    selectedItem: {},
    categoryList: [],
    categoryGroup: [],
    categoryForImg: {},
    bookmark: false,
    bookmarkList: [],
    selectedCategory: [],
    popup: {
        floorDetailPopup: false,
        filteringPopup: false,
    },
};

const { actions, reducer } = createSlice({
    name: 'realTimeLocation',
    initialState,
    reducers: {
        setFloorInfo: (state, action) => {
            state.floorInfo = { ...action.payload };
        },
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },
        setGeofenceInfo: (state, action) => {
            const { geofenceInfo, geofenceInOutState } = action.payload;
            state.geofenceInfo = geofenceInfo.map(geofence => {
                const inOutInfo = geofenceInOutState[geofence.fcNum] || {};
                return {
                    ...geofence,
                    bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                    authorized: inOutInfo.authorized,
                    unAuthorized: inOutInfo.unAuthorized,
                };
            });
        },

        setCategoryList: (state, action) => {
            state.categoryList = [...action.payload];
        },
        setCategoryGroup: (state, action) => {
            state.categoryGroup = [...action.payload].filter(v => v.depth === 0).map(v => v.categoryCodePath);
        },
        setCategoryImg: (state, action) => {
            state.categoryForImg = { ...action.payload };
        },
        setRealTimeLog: (state, action) => {
            state.realTimeLog = action.payload;
        },
        setIotItemList: (state, action) => {
            const { iotItemList, t } = action.payload;

            state.iotItemList = iotItemList.map(item => {
                return {
                    value: item.targetId ? item.targetId : item.value,
                    label: item.targetName
                        ? `(name) ${item.targetName} | (ID) ${item.targetId} > ${
                              !item.lastDate || item.lastDate === 0 || item.lostSignalStatus === 'LOSTSIGNAL_ON'
                                  ? t('RealTimeLocationStatus;Signal Not Detected')
                                  : t('RealTimeLocationStatus;Signal Detected')
                          }`
                        : item.label,
                };
            });
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = { ...action.payload };
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setBookmark: (state, action) => {
            state.bookmark = action.payload;
        },
        setBookmarkList: (state, action) => {
            state.bookmarkList = action.payload;
        },
        setPopup: (state, action) => {
            state.popup = { ...state.popup, ...action.payload };
        },
        clearData: (state, action) => {
            state.selectedItem = {};
            state.selectedFloor = '';
            state.floorInfo = {};
            state.geofenceInfo = [];
            state.realTimeLog = { count: 0, data: [] };
        },
    },
});

export const {
    setFloorInfo,
    setSelectedFloor,
    setGeofenceInfo,
    setCategoryList,
    setCategoryGroup,
    setCategoryImg,
    setIotItemList,
    setSelectedItem,
    setRealTimeLog,
    setBookmark,
    setBookmarkList,
    setSelectedCategory,
    setPopup,
    clearData,
} = actions;
export default reducer;
