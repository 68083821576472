import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import message_ko from './lang/message_ko.json';
import message_en from './lang/message_en.json';
import custom_message_ko from './lang/custom/message_ko.json';
import custom_message_en from './lang/custom/message_en.json';
import cloneDeep from 'lodash/cloneDeep';

function mergeJson(base, add) {
    const mergedJson = cloneDeep(base);

    for (let key in add) {
        const exist = mergedJson[key];
        if (exist) {
            mergedJson[key] = { ...exist, ...add[key] };
        } else {
            mergedJson[key] = add[key];
        }
    }

    return mergedJson;
}

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    ko: {
        translation: mergeJson(message_ko, custom_message_ko),
    },
    en: {
        translation: mergeJson(message_en, custom_message_en),
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'ko',

    keySeparator: ';', // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
