import React, { useContext } from 'react';
import SensorReport from './Components/SensorReport';
import { SensorStatusStateContext } from '../../index';
import SensorDetails from './Components/SensorDetails';

const SensorDetailInfo = () => {
    const { reportType } = useContext(SensorStatusStateContext);

    return (
        <>
            <SensorReport />
            {reportType === 'daily' && <SensorDetails />}
        </>
    );
};

export default SensorDetailInfo;
