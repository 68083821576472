import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selectedIotItemStatus: null,
    selectedIotItem: null,
    iotItemCategoryOptions: [],
    iotItemList: {
        totalCount: 0,
        totalPage: 1,
        rows: [],
    },
    iotItemListParam: {},
    iotItemCountParam: {},
    searchInput: {
        target: '',
        floorIds: '',
    },
    floorInfo: null,
};

const { actions, reducer } = createSlice({
    name: 'iotItemStatus',
    initialState,
    reducers: {
        setSelectedIotItemStatus: (state, action) => {
            state.selectedIotItemStatus = action.payload;
        },
        setSelectedIotItem: (state, action) => {
            state.selectedIotItem = action.payload;
        },
        setIotItemCategoryOptions: (state, action) => {
            state.iotItemCategoryOptions = action.payload;
        },
        setIotItemList: (state, action) => {
            state.iotItemList = { pageSize: 15, ...action.payload };
        },
        setIotItemListParam: (state, action) => {
            state.iotItemListParam = { ...state.iotItemListParam, ...action.payload };
        },
        setIotItemCountParam: (state, action) => {
            state.iotItemCountParam = { ...state.iotItemCountParam, ...action.payload };
        },
        setSearchInput: (state, action) => {
            state.searchInput = { ...state.searchInput, ...action.payload };
        },
        setFloorInfo: (state, action) => {
            state.floorInfo = action.payload;
        },
        clearIotItemStatusWidget: (state, action) => {
            state.searchInput = {
                target: '',
            };
            state.iotItemListParam = {};
            state.selectedIotItemStatus = null;
            state.selectedIotItemCategory = null;
        },
    },
});

export const {
    setSelectedIotItemStatus,
    setSelectedIotItemCategory,
    setSelectedIotItem,
    setIotItemCategoryOptions,
    setIotItemList,
    setIotItemListParam,
    setIotItemCountParam,
    setSearchInput,
    setFloorInfo,
    clearIotItemStatusWidget,
} = actions;

export default reducer;
