import { createSlice } from '@reduxjs/toolkit';

export const INTERVAL_TIME = 1000;

export const initialState = {
    sensingTypeOptions: [],
    sensorListParam: { pageSize: 15, page: 1 },
    searchInput: {},
    selectedStatus: null,
    selectedSensorStatusList: { rows: [], totalCount: 0, totalPage: 1 },
    sensorDetailsList: { rows: [], totalCount: 0, totalPage: 1 },
    sensorDetailsListLoading: false,
    selectedSensor: null,
    monthlyPrintPopup: false,
    locationPopup: false,
    reportType: 'daily',
    sensorLineChartData: [],
    sensorLogInfo: { request: null },
    floorId: null,
};

const { actions, reducer } = createSlice({
    name: 'sensorStatus',
    initialState,
    reducers: {
        setSensingTypeOptions: (state, action) => {
            state.sensingTypeOptions = action.payload;
        },
        setSensorListParam: (state, action) => {
            state.sensorListParam = { ...state.sensorListParam, ...action.payload };
        },
        setSelectedStatus: (state, action) => {
            let selectedStatus = action.payload;
            if (selectedStatus) {
                state.selectedStatus = selectedStatus.toUpperCase();
            } else {
                state.selectedStatus = null;
                state.selectedSensorStatusList = { rows: [], totalCount: 0, totalPage: 1 };
            }
        },
        setSelectedSensorStatusList: (state, action) => {
            const { rows, ...listInfo } = action.payload;
            state.selectedSensorStatusList = {
                ...listInfo,
                rows: rows.reduce((acc, cur) => {
                    acc = acc.concat(cur.sensorItems.map(sensorItem => ({ ...cur, sensorItems: [sensorItem] })));
                    return acc;
                }, []),
            };
        },
        setSensorDetailsList: (state, action) => {
            const { data: list, loading, sensorLogInfo, chartData } = action.payload;

            state.sensorLineChartData = chartData;

            state.sensorDetailsList = {
                ...list,
                rows: chartData,
            };
            state.sensorDetailsListLoading = loading;
            state.sensorLogInfo = sensorLogInfo;
        },
        setSelectedSensor: (state, action) => {
            state.selectedSensor = action.payload;
            if (action.payload) {
                state.floorId = action.payload.floorId;
            } else {
                state.floorId = null;
                state.sensorDetailsList = { rows: [], totalCount: 0, totalPage: 1 };
            }
        },
        setMonthlyPrintPopup: (state, action) => {
            state.monthlyPrintPopup = action.payload;
        },
        setLocationPopup: (state, action) => {
            state.locationPopup = action.payload;
        },
        setReportType: (state, action) => {
            state.reportType = action.payload;
        },
        clearSensorStatusWidget: (state, action) => {
            state.sensorListParam = {
                pageSize: 15,
                page: 1,
            };
        },
    },
});

export const {
    setSensingTypeOptions,
    setSensorListParam,
    setSelectedStatus,
    setSelectedSensorStatusList,
    setSensorDetailsList,
    setSelectedSensor,
    setMonthlyPrintPopup,
    setLocationPopup,
    setReportType,
    clearSensorStatusWidget,
} = actions;

export default reducer;
