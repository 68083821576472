import { reqGet } from './index';

//가장 혼잡한 지오펜스 Top 5
export const fetchGeofenceCongestionStatus = param =>
    reqGet('/v3/api/common/statistics/geofence/log/by-enter/count', { ...param });

//트리거 된 알림 현황
export const fetchTriggeredAlertStatus = param =>
    reqGet('/v3/api/common/statistics/notification/by-event/count', { ...param });

// 혼잡도 api
export const fetchCongestionStatusApi = param => reqGet('/v3/api/common/crowdedness/zones/stat/summary', { ...param });

// 지오펜스 혼잡 상태별 수 조회 api
export const fetchMostCongestedGeofenceApi = param => reqGet('/v3/api/common/crowdedness/zones/stat', { ...param });

// 시간별 입출입 횟수 조회 api
export const fetchEntryCountInSelectedTimeRangeApi = param =>
    reqGet('/v3/api/common/crowdedness/zones/stat/by-date/count', { ...param });

// 시간별 혼잡 상태 조회 api
export const fetchEntryCountByHoursApi = param =>
    reqGet('/v3/api/common/crowdedness/zones/stat/by-time/count', { ...param });
