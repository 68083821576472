import React from 'react';
import cx from 'classnames';

export const InputGroup = ({ children }) => {
    return <div className={'pnt-input--group'}>{children}</div>;
};

export const InputWrap = ({ children }) => {
    return <div className={'input-wrap'}>{children}</div>;
};

export const FilterList = ({ children, className }) => {
    return <div className={cx('filter-list', className && className)}>{children}</div>;
};

export const SearchWrap = ({ children, className }) => {
    return <div className={cx('search-wrap', className)}>{children}</div>;
};

export const SelectGroup = ({ children, className }) => {
    return <div className={cx('pnt-select--group', className)}>{children}</div>;
};
