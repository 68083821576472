import React from 'react';
import cx from 'classnames';

const LoginTitle = ({ alignCenter, title, subTitle }) => {
    return (
        <div className="title-box">
            <h2 className={cx('txt__main', alignCenter && 'w-100 tc')}>{title}</h2>
            <h3 className={cx('txt__sub', alignCenter && 'w-100 tc')}>{subTitle}</h3>
        </div>
    );
};

export default LoginTitle;
