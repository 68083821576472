import React, { useContext } from 'react';
import CountUp from 'react-countup';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import cx from 'classnames';
import { SensorStatusDispatchContext } from '../../../index';
import { setSelectedStatus } from '../../../sensorStatusReducer';

const CustomizedCountUp = styled(CountUp)`
    &.clickable:hover {
        color: orange !important;
        cursor: pointer;
    }
`;

const CountWrapper = ({ children }) => {
    return <Col>{children}</Col>;
};

const ItemCount = ({ start = 0, end = 0, label, status, clickable = false }) => {
    const dispatch = useContext(SensorStatusDispatchContext);

    const handleCountClick = status => {
        dispatch(setSelectedStatus(status));
    };

    return (
        <CountWrapper>
            <div className={'flex-center'} style={{ flexDirection: 'column', height: '100%' }}>
                <div
                    onClick={() => {
                        if (status !== 'total') {
                            handleCountClick(status);
                        }
                    }}
                >
                    <CustomizedCountUp
                        className={cx('font-weight-bold', clickable && 'clickable')}
                        style={{ fontSize: '2.5rem' }}
                        start={start}
                        end={end}
                        separator=""
                        decimals={0}
                        decimal=","
                        prefix=""
                        useEasing={false}
                        suffix=""
                        duration="1"
                    />
                </div>
                <h6
                    className={'mt-2'}
                    style={{ fontWeight: 500, fontSize: '0.88rem', textAlign: 'center', wordBreak: 'keep-all' }}
                >
                    {label}
                </h6>
            </div>
        </CountWrapper>
    );
};

export default ItemCount;
