import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bookMarkedList: [],
};

const { actions, reducer } = createSlice({
    name: 'bookMarkedItem',
    initialState,
    reducers: {
        setBookMarkedList: (state, action) => {
            state.bookMarkedList = action.payload;
        },
    },
});

export const { setBookMarkedList } = actions;
export default reducer;
