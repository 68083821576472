import React from 'react';
import SensorAndIotCount from './Components/SensorAndIotCount';
import SensorStatusCount from './Components/SensorStatusCount';

// 전체 IoT 아이템 & 센서 수, 전체 상태 별 측정항목 수
const SensorCount = ({ targetCountData, statusCountData }) => {
    return (
        <>
            <SensorAndIotCount targetCountData={targetCountData} />
            <SensorStatusCount statusCountData={statusCountData} />
        </>
    );
};

export default SensorCount;
