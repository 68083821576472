import React from 'react';
import { FeatureGroup, Polygon, Tooltip } from 'react-leaflet';

const GeofenceLayer = ({ selectedGeofence, geofenceList, handleClick, authState }) => {
    return (
        <FeatureGroup>
            {geofenceList.map(
                geofence =>
                    geofence.latLngList.length > 0 && (
                        <Polygon
                            color={selectedGeofence && selectedGeofence.fcNum === geofence.fcNum ? 'red' : 'blue'}
                            key={geofence.fcNum}
                            positions={geofence.bounds}
                            onClick={e => {
                                if (typeof handleClick === 'function') {
                                    handleClick(geofence);
                                }
                            }}
                        >
                            <Tooltip
                                key={`tooltip-${geofence.fcNum}`}
                                permanent={true}
                                interactive={true}
                                direction={'center'}
                                className={'geofence-name-label'}
                            >
                                <div
                                    className={'map-text'}
                                    //style={{ textAlign: 'center',color: 'rgba(0, 0, 0, 0.75)' }}
                                >
                                    {geofence.fcName}
                                </div>
                                {authState && (
                                    <div
                                        className={'map-text'}
                                        //style={{ textAlign: 'center',    color: 'rgba(0, 0, 0, 0.75)' }}
                                    >
                                        (
                                        {`${geofence.authorized ? geofence.authorized.length : 0} / ${
                                            geofence.unAuthorized ? geofence.unAuthorized.length : 0
                                        }`}
                                        )
                                    </div>
                                )}
                            </Tooltip>
                        </Polygon>
                    ),
            )}
        </FeatureGroup>
    );
};
export default GeofenceLayer;
