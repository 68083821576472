import React from 'react';
import cx from 'classnames';

const Checkbox = ({ name, id, className, disabled, checked, handleChecked, inputName, ...restProps }) => {
    return (
        <label
            className={cx('pnt-checkbox', checked && 'check-checked', disabled && 'form-disable', className)}
            htmlFor={id}
        >
            <input name={inputName} type="checkbox" id={id} onChange={handleChecked} checked={checked} {...restProps} />
            <span className="checkbox-effect" />
            <p>{name}</p>
        </label>
    );
};

export default Checkbox;
