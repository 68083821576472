import { reqDelete, reqGet, reqPost, reqPut } from './index';

export const getFilterListApi = param => reqGet('/v3/api/common/filter/info', { ...param });

export const createFilterApi = param => reqPost('/v3/api/common/filter/info', { ...param });

export const copyFilterApi = param => reqPut(`/v3/api/common/filter/info/copy/${param.filterNum}`, { ...param });

export const getSelectedFilterApi = param => reqGet(`/v3/api/common/filter/info/${param.filterNum}`);

export const deleteFilterApi = param => reqDelete(`/v3/api/common/filter/info/${param.filterNum}`);

export const editFilterApi = ({ filterNum, ...restParam }) =>
    reqPut(`/v3/api/common/filter/info/${filterNum}`, restParam);
